import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { ButtonProps, Button as ChakraButton } from "@chakra-ui/react";

interface ButtonProp extends ButtonProps {
  children?: string;
  iconType?: "Add" | "Remove";
}

const Button: React.FC<ButtonProp> = ({ children, iconType, ...props }) => {
  let rightIcon = null;
  switch (iconType) {
    case "Add":
      rightIcon = <AddIcon fontSize={12} />;
      break;
    case "Remove":
      rightIcon = <CloseIcon fontSize={12} />;
      break;
    default:
      break;
  }

  return (
    <ChakraButton
      colorScheme="blackAlpha"
      variant="outline"
      size="sm"
      pl={4}
      pr={4}
      pt={1}
      pb={1}
      fontSize="sm"
      color="darkslategray"
      rightIcon={rightIcon ?? undefined}
      outline="1px solid"
      h="auto"
      w="fit-content"
      alignSelf="flex-start"
      {...props}
    >
      {children}
    </ChakraButton>
  );
};

export default Button;
