import { Fragment } from "react";

import { VStack, HStack, Checkbox, Text } from "@chakra-ui/react";
import { Section, Page } from "@shared/models";
interface SectionNavigationProps {
  sections: Section[];
  getSectionState: (section: Section) => { allChecked: boolean; isIndeterminate: boolean };
  checkedItems: {
    [key: string]: {
      [key: string]: boolean;
    };
  };
  handlePageClick: (section: Section, page: Page) => void;
}

const SectionNavigation: React.FC<SectionNavigationProps> = ({
  sections,
  getSectionState,
  checkedItems,
  handlePageClick
}) => {
  return (
    <VStack alignItems="flex-start" w="350px" h="full">
      {sections.map((section) => {
        const { allChecked, isIndeterminate } = getSectionState(section);
        return (
          <Fragment key={section.name}>
            <HStack>
              <Checkbox colorScheme="brand" isChecked={allChecked} isIndeterminate={isIndeterminate}>
                <Text>{section.name}</Text>
              </Checkbox>
            </HStack>
            {section.pages.map((page, index) => {
              const id = `${section.name}-${page.name}-${index}`;
              return (
                <Checkbox
                  key={id}
                  colorScheme="brand"
                  isChecked={checkedItems[section.name]?.[page.name] || false}
                  onChange={() => handlePageClick(section, page)}
                  ml={5}
                >
                  <Text>{page.name}</Text>
                </Checkbox>
              );
            })}
          </Fragment>
        );
      })}
    </VStack>
  );
};

export default SectionNavigation;
