import { useState } from "react";

import { Box, Button, Code, Divider, Heading, HStack, VStack } from "@chakra-ui/react";

import ComponentPreview from "../../components/ComponentPreview";
// import Context from "../../components/Context";
import OtherMultiSelect from "../../components/MultiSelect/OtherMultiSelect";
import { BooleanOptionsListTester } from "../../components/test/BooleanOptionsListTester";
import { CustomItemsWithDefinedOptionsListTester } from "../../components/test/CustomItemsWithDefinedOptionsListTester";
import { SOPContextTester } from "../../components/test/SOPContextTester";
import { TextInputTesterComponent } from "../../components/test/TextInputTester";
import { useUserInfo } from "../../hooks/useUserInfo";
// import { useUser } from "@clerk/clerk-react";

const generalRender = true;

const defaultItems = [...Array(10)].map((_, index) => `Item ${index + 1}`);
// const defaultItems2 = [
//   "Item with a really long name to see how it displays",
//   "Item with a short name",
//   "Item with a really long name to see how it displays",
//   "Item with a really long name to see how it displays Item",
// ];

const HomePage = () => {
  const userInfo = useUserInfo("12345");
  const [selectedItems, setSelectedItems] = useState<string[]>(["Item 5"]);

  const handleButtonClick = (item: string) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(item) ? prevSelectedItems.filter((i) => i !== item) : [...prevSelectedItems, item]
    );
  };

  const handleInputAdd = (value: string) => {
    setSelectedItems((prevSelectedItems) => [...prevSelectedItems, value]);
  };

  return (
    <VStack w="full" h="100%" alignItems="stretch" overflow={"hidden"}>
      <Heading>Component Review</Heading>

      <HStack flexDir="column" pt={10} w={900} gap={5} alignItems="flex-start">
        <TextInputTesterComponent render={generalRender} />

        <BooleanOptionsListTester render={generalRender} />

        <CustomItemsWithDefinedOptionsListTester render={generalRender} />

        <SOPContextTester render={generalRender} />

        <Divider />

        <Box h={200} />

        <ComponentPreview title="MultiSelect List With Other" render={generalRender}>
          <OtherMultiSelect
            selectedItems={selectedItems}
            defaultItems={defaultItems}
            onButtonClick={handleButtonClick}
            onAddClick={handleInputAdd}
          />
        </ComponentPreview>

        <ComponentPreview title="Create MultiSelect From Nothing" render={generalRender}>
          <OtherMultiSelect
            selectedItems={selectedItems}
            onButtonClick={handleButtonClick}
            onAddClick={handleInputAdd}
          />
        </ComponentPreview>

        {/* <Divider />
        <ComponentPreview title="MultiSelect List With Short Items" render={false}>
          <Text color="red.600" as="i">I'm not sure if we will use ListMultiSelect anymore...</Text>
          <ListMultiSelect
            selectedItems={selectedItems}
            defaultItems={defaultItems}
            onButtonClick={handleButtonClick}
          />
        </ComponentPreview>

        <ComponentPreview title="MultiSelect List With Long Items (Column mode)" render={false}>
          <Text color="red.600" as="i">I'm not sure if we will use ListMultiSelect anymore...</Text>
          <ListMultiSelect
            // selectedItems={selectedItems}
            defaultItems={defaultItems2}
            onButtonClick={() => { }}
            direction="column"
          />
        </ComponentPreview> */}

        <Button colorScheme="green" onClick={() => userInfo.refetch()}>
          Refetch
        </Button>
        {userInfo.data && (
          <pre style={{ paddingTop: 20 }}>
            <Code>{JSON.stringify(userInfo.data, null, 2)}</Code>
          </pre>
        )}
      </HStack>
    </VStack>
  );
};

export default HomePage;
