import { Box, Spinner } from "@chakra-ui/react";
import { useClerk, useUser } from "@clerk/clerk-react";
import { Navigate } from "react-router-dom";

type PrivateRouteProps = {
  children: React.ReactNode;
  redirectTo?: string;
};

const RequireAuth = ({ children, redirectTo = "/login" }: PrivateRouteProps) => {
  const clerk = useClerk();
  const user = useUser();

  if (!clerk.loaded) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="lg" />
      </Box>
    );
  }

  const isAuthenticated = user.isSignedIn;
  return isAuthenticated ? (children as React.ReactElement) : <Navigate to={redirectTo} />;
};

export default RequireAuth;
