export interface UserInfo {
  id: number;
  authId: string;
  emailAddress?: string;
  isAdmin: boolean;
  fetchedAt: Date;
}

export enum QuestionType {
  // Text,
  // BigText,
  // Checkbox,
  // CheckboxGroup,
  // Select,
  // CustomSelect,
  // MultiSelect,
  // Radio,
  // Date,
  // None
  ConfirmContext,
  MultiselectWithOther,
  YesNo,
  Textarea
}

export interface Question {
  id: string;
  name: string;
  type: QuestionType;
  intro?: string;
  inputMask?: "text" | "number";
  prompt: string;
  options?: Array<string>;
  required?: boolean;
  footnote?: string;
  excludeFromReport?: boolean;
  contentFile?: string;
}

export interface Page {
  id: string;
  urlSlug: string;
  name: string;
  header: string;
  questions: Array<Question>;
}

export interface Section {
  id: string;
  name: string;
  urlSlug: string;
  pages: Array<Page>;
}

export interface SOPGuide {
  sections: Array<Section>;
}
