import { useState } from "react";

import { VStack, Heading } from "@chakra-ui/react";
import { QuestionType, Question } from "@shared/models";

import Context from "./Context";
import OtherMultiSelect from "./MultiSelect/OtherMultiSelect";
import TextInput from "./TextInput";
import { YesNoSelection } from "./YesNoSelection";
import { Session } from "../store";

interface DynamicQuestionsProps {
  question: Question;
  handleNextClick: () => void;
  session: Session;
  setSession: (session: Session) => void;
}

const DynamicQuestion: React.FC<DynamicQuestionsProps> = ({ question, handleNextClick, session, setSession }) => {
  const [inputValue, setInputValue] = useState<string>(question.options?.join(" ") ?? "");

  return (
    <VStack key={question.name} alignItems="flex-start" justifyContent="flex-start" w="full" gap={4}>
      <Heading size="lg">{question.name}</Heading>

      {question.type === QuestionType.ConfirmContext && (
        <Context
          contentFile={question.contentFile || ""}
          question={question.name || ""}
          onAffirmativeClick={handleNextClick}
        />
      )}

      {question.type === QuestionType.MultiselectWithOther && (
        <OtherMultiSelect
          selectedItems={session[question.id]?.answer as string[]}
          defaultItems={question.options || []}
          onButtonClick={() => {}}
          onAddClick={() => {}}
        />
      )}

      {question.type === QuestionType.YesNo && (
        <YesNoSelection
          selected={session[question.id]?.answer as boolean}
          onAffirmativeClick={() => {}}
          onNegativeClick={() => {}}
        />
      )}

      {question.type === QuestionType.Textarea && (
        <TextInput
          value={(session[question.id]?.answer as string) || inputValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => console.log(e.target.value)}
          placeholder="Type anything..."
        />
      )}

      {/* Uncomment and implement if needed
          {question.type === "multiselect" && (
            <ListMultiSelect
              options={question.options || []}
              prefilledValue={question.prefilledValue || ""}
              onChange={handleNextClick}
            />
          )}
          */}
      {/* <Text>{question.type}</Text> */}
    </VStack>
  );
};

export default DynamicQuestion;
