import { useEffect, useState } from "react";

import { Stack } from "@chakra-ui/react";

import ListMultiSelect from "./ListMultiSelect";
import TextInput from "../TextInput";

interface OtherMultiSelectProps {
  selectedItems?: string[];
  defaultItems?: string[];
  items?: string[];
  onButtonClick: (item: string) => void;
  direction?: "row" | "column";
  onAddClick?: (value: string) => void;
}

const OtherMultiSelect: React.FC<OtherMultiSelectProps> = ({
  selectedItems,
  defaultItems,
  onButtonClick,
  onAddClick
}) => {
  const [unselectedItems, setUnselectedItems] = useState<string[]>(
    defaultItems?.filter((item) => !selectedItems?.includes(item)) || []
  );
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    const remainingItems = defaultItems?.filter((item) => !selectedItems?.includes(item)) || [];
    setUnselectedItems(remainingItems);
  }, [selectedItems]);

  const handleButtonClick = (item: string) => {
    onButtonClick(item);
  };

  const handleAddClick = (value: string) => {
    if (onAddClick) {
      onAddClick(value);
      setValue("");
    }
  };

  return (
    <Stack direction="column" spacing={3} w="full" h="fit-content" flexWrap="wrap">
      <ListMultiSelect selectedItems={[...(selectedItems || [])]} onButtonClick={handleButtonClick} />

      <TextInput
        showAdd={true}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
        onAddClick={handleAddClick}
      />

      <ListMultiSelect defaultItems={[...(unselectedItems || [])]} onButtonClick={handleButtonClick} />
    </Stack>
  );
};

export default OtherMultiSelect;
