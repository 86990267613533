import { QuestionType, UserInfo } from "@shared/models";
import { create } from "zustand";

export interface Message {
  messageid: string;
  accountid: string;
  sender: string;
  message: string;
  datetime: string;
}

export interface Conversation {
  conversationid: number;
  messages: Message[];
}

interface WorkflowUrlSlug {
  sectionUrlSlug: string;
  pageUrlSlug: string;
}

export interface Session {
  [key: string]: {
    type: QuestionType;
    answer: string | string[] | boolean;
  };
}

interface AppState {
  userInfo?: UserInfo;
  filter: string;
  currentUrlSlug: WorkflowUrlSlug;
  session: Session;
  allConversations: Conversation[];
  filteredConversations: Conversation[];
  setUserInfo: (userInfo: UserInfo) => void;
  setFilter: (filter: string) => void;
  setSession: (session: Session) => void;
  setCurrentUrlSlug: (urlSlug: WorkflowUrlSlug) => void;
  setAllConversations: (all: Conversation[]) => void;
  setFilteredConversations: (filtered: Conversation[]) => void;
}

export const useAppStore = create<AppState>()((set) => ({
  userInfo: undefined,
  filter: "",
  currentUrlSlug: {
    sectionUrlSlug: "",
    pageUrlSlug: ""
  },
  session: {},
  allConversations: [],
  filteredConversations: [],
  setUserInfo: (userInfo) => set(() => ({ userInfo })),
  setCurrentUrlSlug: (urlSlug) => set(() => ({ currentUrlSlug: urlSlug })),
  setSession: (session) => set(() => ({ session })),
  setAllConversations: (all) => set(() => ({ allConversations: all })),
  setFilteredConversations: (filtered) => set(() => ({ filteredConversations: filtered })),
  setFilter: (filter) => set(() => ({ filter }))
}));
