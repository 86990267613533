import { Button, Text, VStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Container } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";

import DynamicQuestion from "../../components/DynamicQuestion";
import { workflow } from "../../constants/workflow";
import { useAppStore } from "../../store";

const SopPage: React.FC = () => {
  const navigate = useNavigate();
  const { currentUrlSlug, session, setSession } = useAppStore();
  const { sectionUrlSlug, pageUrlSlug } = currentUrlSlug;

  const isIndex = !pageUrlSlug;
  const notReady = pageUrlSlug === "not-yet";

  const currentSection = workflow.sections.find((sec) => sec.urlSlug === sectionUrlSlug);
  const currentPage = currentSection?.pages.find((pg) => pg.urlSlug === pageUrlSlug);

  if (isIndex) {
    return (
      <VStack w="full" h="100%" alignItems="stretch" overflow={"hidden"}>
        <Text>&lt;-- Click a link</Text>
      </VStack>
    );
  }

  if (notReady) {
    return (
      <VStack w="full" h="100%" alignItems="stretch" overflow={"hidden"}>
        <Text>This page isn't enabled yet - you go back</Text>
        <Button onClick={() => navigate(-1)} colorScheme="blue" w="fit-content">
          Go to Sop Builder
        </Button>
      </VStack>
    );
  }

  if (!currentSection || !currentPage) {
    return (
      <VStack w="full" h="100%" alignItems="stretch" overflow={"hidden"}>
        <Text>Section or Page not found.</Text>
      </VStack>
    );
  }

  return (
    <VStack w="full" h="100%" alignItems="stretch">
      <VStack alignItems="flex-start" justifyContent="flex-start" w="full" h="full" position="relative">
        <Breadcrumb mb={4}>
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigate(`/sop-builder/${currentSection.urlSlug}/${currentPage.urlSlug}`)}>
              {currentSection.name}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{currentPage.name}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Container maxWidth="container.lg" h="100%" mt={10}>
          <VStack alignItems="flex-start" justifyContent="flex-start" w="full" h="100%" gap={20}>
            {currentPage.questions.map((question) => (
              <DynamicQuestion
                key={question.id}
                question={question}
                // handleNext should be handled by a navigation hook
                handleNextClick={() => console.log("next")}
                session={session}
                setSession={(s) => setSession({ ...(session ?? {}), ...s })}
              />
            ))}
          </VStack>
        </Container>
      </VStack>
    </VStack>
  );
};

export default SopPage;
