import { QuestionType, SOPGuide } from "@shared/models";

export const workflow: SOPGuide = {
  sections: [
    {
      id: "handwashing",
      urlSlug: "handwashing",
      name: "Handwashing",
      pages: [
        {
          id: "handwashing-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "Proper Use of Handwashing",
          questions: [
            {
              id: "handwashing-proper-use-understand",
              name: "Do you understand the rules for proper handwashing?",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand the rules for proper handwashing?",
              contentFile: "/SOPContext/Handwashing.md",
              intro: "Please review the following information about proper handwashing.",
              required: true,
              footnote: "Proper handwashing is crucial for food safety.",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "personal-hygiene",
      urlSlug: "personal-hygiene",
      name: "Personal Hygiene",
      pages: [
        {
          id: "personal-hygiene-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "Proper Personal Hygiene",
          questions: [
            {
              id: "personal-hygiene-proper-use-understand",
              name: "Do you understand the rules for proper personal hygiene?",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand the rules for proper personal hygiene?",
              contentFile: "/SOPContext/PersonalHygiene.md",
              intro: "Please review the following information about proper personal hygiene.",
              required: true,
              footnote: "Maintaining proper personal hygiene is essential in food handling.",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "personal-hygiene-food-drink",
          urlSlug: "food-drink",
          name: "Food & Drink",
          header: "Food & Drink Policies",
          questions: [
            {
              id: "personal-hygiene-food-drink-where",
              name: "Where will employees be permitted to eat and drink",
              type: QuestionType.MultiselectWithOther,
              prompt: "Where will employees be permitted to eat and drink?",
              options: ["courtyard", "break room"],
              intro: "Select all areas where employees are allowed to eat and drink.",
              required: true,
              footnote: "Eating and drinking should be restricted to designated areas.",
              excludeFromReport: false
            },
            {
              id: "personal-hygiene-food-drink-drinks",
              name: "Will you allow drinks in the food handling area?",
              type: QuestionType.YesNo,
              prompt: "Will you allow drinks in the food handling area?",
              intro: "Consider the implications of allowing drinks in food handling areas.",
              required: true,
              footnote: "Drinks in food handling areas may pose contamination risks.",
              excludeFromReport: false
            },
            {
              id: "personal-hygiene-food-drink-containers",
              name: "What types of containers will be used?",
              type: QuestionType.Textarea,
              prompt: "What types of containers will be used?",
              options: ["Plastic, Glass, etc."],
              intro: "Specify the types of containers that will be allowed for drinks.",
              required: true,
              footnote: "Proper containers can help prevent spills and contamination.",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "personal-hygiene-tobacco-products",
          urlSlug: "tobacco-products",
          name: "Tobacco Products",
          header: "Tobacco Products Policy",
          questions: [
            {
              id: "personal-hygiene-tobacco-products-where",
              name: "Where will employees be permitted to use tobacco products",
              type: QuestionType.MultiselectWithOther,
              prompt: "Where will employees be permitted to use tobacco products?",
              options: ["courtyard", "break room"],
              intro: "Select all areas where tobacco use will be allowed.",
              required: true,
              footnote: "Tobacco use should be restricted to designated areas away from food handling.",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "personal-hygiene-hair-restraint",
          urlSlug: "hair-restraint",
          name: "Hair Restraint",
          header: "Hair Restraint Policy",
          questions: [
            {
              id: "personal-hygiene-hair-restraint-types",
              name: "What types of hair restraints will be used in your facility",
              type: QuestionType.MultiselectWithOther,
              prompt: "What types of hair restraints will be used in your facility?",
              options: ["Hair Nets", "Hair Caps", "Hair Ties"],
              intro: "Select all types of hair restraints that will be used.",
              required: true,
              footnote: "Proper hair restraints help prevent contamination of food.",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "bare-hand-contact",
      urlSlug: "bare-hand-contact",
      name: "Bare Hand Contact",
      pages: [
        {
          id: "bare-hand-contact-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "Proper Use of Bare Hand Contact",
          questions: [
            {
              id: "bare-hand-contact-proper-use-understand",
              name: "Do you understand the rules for proper bare hand contact?",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand the rules for proper bare hand contact?",
              contentFile: "/SOPContext/BareHandContact.md",
              intro: "Please review the following information about proper bare hand contact.",
              required: true,
              footnote: "Proper bare hand contact is crucial for food safety.",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "ready-to-eat-utensils",
          urlSlug: "ready-to-eat-utensils",
          name: "Ready-to-eat Utensils",
          header: "Ready-to-eat Utensils",
          questions: [
            {
              id: "ready-to-eat-utensils-serve-with-utensils",
              name: "Please select all items that apply to how you will serve RTE foods with utensils",
              type: QuestionType.MultiselectWithOther,
              prompt: "Please select all items that apply to how you will serve RTE foods with utensils",
              options: ["Option 1", "Option 2", "Option 3"], // Replace with actual options
              required: true,
              footnote: "Ensure all selected items comply with safety standards.",
              excludeFromReport: false
            },
            {
              id: "ready-to-eat-utensils-serve-with-disposable-gloves",
              name: "Please select all items that apply to how you will serve RTE foods with disposable gloves",
              type: QuestionType.MultiselectWithOther,
              prompt: "Please select all items that apply to how you will serve RTE foods with disposable gloves",
              options: ["Option A", "Option B", "Option C"], // Replace with actual options
              required: true,
              footnote: "Disposable gloves must be changed regularly to maintain hygiene.",
              excludeFromReport: false
            },
            {
              id: "ready-to-eat-utensils-serve-with-dispensing-equipment",
              name: "Please select all items that apply to how you will serve RTE foods with dispensing equipment",
              type: QuestionType.MultiselectWithOther,
              prompt: "Please select all items that apply to how you will serve RTE foods with dispensing equipment",
              options: ["Option X", "Option Y", "Option Z"], // Replace with actual options
              required: true,
              footnote: "Dispensing equipment should be sanitized after each use.",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "employee-health",
      urlSlug: "employee-health",
      name: "Employee Health",
      pages: [
        {
          id: "employee-health-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "Proper Use of Employee Health",
          questions: [
            {
              id: "employee-health-proper-use-understand",
              name: "Do you understand the rules for proper employee health management?",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand the rules for proper employee health management?",
              contentFile: "/SOPContext/EmployeeHealth.md",
              intro: "Please review the following information about managing employee health.",
              required: true,
              footnote: "Proper management of employee health is essential for maintaining food safety.",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "employee-health-reporting",
          urlSlug: "employee-reporting",
          name: "Employee Reporting",
          header: "Employee Reporting",
          questions: [
            {
              id: "employee-health-reporting-awareness",
              name: "Please select how will you make your employees aware of the rules",
              type: QuestionType.MultiselectWithOther,
              prompt: "Please select how will you make your employees aware of the rules",
              options: ["Training Sessions", "Employee Handbooks", "Email Notifications"], // Replace with actual options
              required: true,
              footnote: "Ensure all employees are adequately informed about health policies.",
              excludeFromReport: false
            },
            {
              id: "employee-health-reporting-records",
              name: "What records will be kept?",
              type: QuestionType.MultiselectWithOther,
              prompt: "What records will be kept?",
              options: ["Health Logs", "Incident Reports", "Training Attendance"], // Replace with actual options
              required: true,
              footnote: "Maintain comprehensive records to comply with regulations.",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "employee-health-recording-illness",
          urlSlug: "recording-illness",
          name: "Recording Illness",
          header: "Recording Illness",
          questions: [
            {
              id: "employee-health-recording-illness-records",
              name: "Will records be kept for food employees' illnesses?",
              type: QuestionType.Textarea,
              prompt: "Will records be kept for food employees' illnesses?",
              options: ["Prefilled answer supplied by John"],
              required: true,
              footnote: "Accurate record-keeping is vital for tracking and managing employee health.",
              excludeFromReport: false
            }
          ]
        }
      ]
    }
  ]
};
