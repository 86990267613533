import { useState } from "react";

import {
  HStack,
  Input as ChakraInput,
  Text,
  Box,
  Flex,
  BoxProps,
  InputProps as ChakraInputProps
} from "@chakra-ui/react";
import { BsArrowReturnLeft } from "react-icons/bs";

import Button from "./Button";

interface TextInputProps extends Omit<ChakraInputProps, "onChange"> {
  showAdd?: boolean;
  placeholder?: string;
  value: string;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onAddClick?: (value: string) => void;
}

const TextInput: React.FC<TextInputProps & BoxProps> = ({
  showAdd,
  placeholder = "Type Here...",
  value = "",
  onChange,
  onAddClick,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
  };

  const handleAddClick = () => {
    if (onAddClick && value) onAddClick(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddClick();
    }
  };

  return (
    <Box w="full" position="relative" onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} {...rest}>
      <HStack borderBottom="2px solid" borderColor="darkslategray">
        <ChakraInput
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          variant="unstyled"
          color="darkslategray"
          colorScheme="darkslategray"
          _focus={{ boxShadow: "none", borderColor: "blue.700" }}
          onKeyDown={handleKeyDown}
          ml={1}
          mb={3}
          mt={1}
          {...rest}
        />
        {showAdd && (
          <Button
            mb={1}
            colorScheme={isFocused ? "blackAlpha" : "gray"}
            color={isFocused ? "darkslategray" : "gray"}
            onClick={() => handleAddClick()}
          >
            Add
          </Button>
        )}
      </HStack>
      {showAdd && isFocused && (
        <Flex position="absolute" bottom={-5} gap={1} right="0" flexDirection="row" alignItems="center">
          <Text fontSize="xs" color="darkslategray">
            Press{" "}
            <Text as="span" fontWeight="bold">
              Enter
            </Text>
          </Text>
          <BsArrowReturnLeft fontSize={13} />
        </Flex>
      )}
    </Box>
  );
};

export default TextInput;
