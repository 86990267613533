import React, { useState, useEffect } from "react";

import { HStack, VStack, Button } from "@chakra-ui/react";
import { Section, Page } from "@shared/models";
import { Outlet, useNavigate } from "react-router-dom";

import SectionNavigation from "../../components/SectionNavigation";
import { sopGuide } from "../../services/sop-builder-service";
import { useAppStore } from "../../store";

interface SectionState {
  [key: string]: {
    [key: string]: boolean;
  };
}

const SopBuilderPage: React.FC = () => {
  const navigate = useNavigate();
  const [checkedItems, setCheckedItems] = useState<SectionState>({});

  const { currentUrlSlug, setCurrentUrlSlug } = useAppStore();

  useEffect(() => {
    // Initialize checkedItems based on sopGuide if needed
  }, []);

  const getSectionState = (section: Section) => {
    const sectionCheckedItems = checkedItems[section.name] || {};
    const allChecked = section.pages.every((page) => sectionCheckedItems[page.name]);
    const someChecked = section.pages.some((page) => sectionCheckedItems[page.name]);
    return { allChecked, isIndeterminate: someChecked && !allChecked };
  };

  const handlePageClick = (section: Section, page: Page) => {
    navigate(`/sop-builder/${section.urlSlug}/${page.urlSlug}`);

    setCurrentUrlSlug({
      sectionUrlSlug: section.urlSlug,
      pageUrlSlug: page.urlSlug
    });

    setCheckedItems((prevState) => ({
      ...prevState,
      [section.name]: {
        ...prevState[section.name],
        [page.name]: true
      }
    }));
  };

  const handleNextClick = () => {
    const { sectionUrlSlug, pageUrlSlug } = currentUrlSlug;

    const currentSection = sopGuide.sections.find((sec) => sec.urlSlug === sectionUrlSlug);
    const currentPage = currentSection?.pages.find((pg) => pg.urlSlug === pageUrlSlug);

    if (!currentSection || !currentPage) return;

    const currentSectionIndex = sopGuide.sections.findIndex((sec) => sec.urlSlug === sectionUrlSlug);
    const currentPageIndex = currentSection.pages.findIndex((pg) => pg.urlSlug === pageUrlSlug);

    let nextSection = currentSection;
    let nextPage = currentPage;

    if (currentPageIndex < currentSection.pages.length - 1) {
      // Move to the next page in the current section
      nextPage = currentSection.pages[currentPageIndex + 1];
      // navigate(`/sop-builder/${currentSection.urlSlug}/${nextPage.urlSlug}`);

      setCurrentUrlSlug({
        sectionUrlSlug: currentSection.urlSlug,
        pageUrlSlug: nextPage.urlSlug
      });
    } else if (currentSectionIndex < sopGuide.sections.length - 1) {
      // Move to the first page of the next section
      nextSection = sopGuide.sections[currentSectionIndex + 1];
      nextPage = nextSection.pages[0];
      // navigate(`/sop-builder/${nextSection.urlSlug}/${nextPage.urlSlug}`);

      setCurrentUrlSlug({
        sectionUrlSlug: nextSection.urlSlug,
        pageUrlSlug: nextPage.urlSlug
      });
    } else {
      // Optionally handle the end of all sections
      alert("You have completed all sections.");
    }

    handlePageClick(nextSection, nextPage);
  };

  return (
    <VStack w="full" h="100%" alignItems="stretch" overflow="hidden" position="relative">
      <HStack w="full" h="full" alignItems="flex-start" gap={10} mt={10}>
        <SectionNavigation
          sections={sopGuide.sections}
          getSectionState={getSectionState}
          checkedItems={checkedItems}
          handlePageClick={handlePageClick}
        />

        <Outlet />

        <Button
          position="absolute"
          bottom={4}
          right={4}
          colorScheme="brand"
          variant="outline"
          onClick={handleNextClick}
        >
          Continue
        </Button>
      </HStack>
    </VStack>
  );
};

export default SopBuilderPage;
