import React, { useState, useEffect } from "react";

import { Button, HStack, VStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Container } from "@chakra-ui/react";
import { Page, Section } from "@shared/models";

import DynamicQuestion from "../components/DynamicQuestion";
import SectionNavigation from "../components/SectionNavigation";
import { workflow } from "../constants/workflow";
import { useAppStore } from "../store";

interface SectionState {
  [key: string]: {
    [key: string]: boolean;
  };
}

const SopDemoPage: React.FC = () => {
  const [currentSection, setCurrentSection] = useState(workflow.sections[0]);
  const [currentPage, setCurrentPage] = useState(currentSection.pages[0]);
  const [checkedItems, setCheckedItems] = useState<SectionState>({});
  const { session, setSession } = useAppStore();

  useEffect(() => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [currentSection.name]: {
        ...prevState[currentSection.name],
        [currentPage.name]: true
      }
    }));
  }, [currentSection, currentPage]);

  const getSectionState = (section: Section) => {
    const sectionCheckedItems = checkedItems[section.name] || {};
    const allChecked = section.pages.every((page) => sectionCheckedItems[page.name]);
    const someChecked = section.pages.some((page) => sectionCheckedItems[page.name]);
    return { allChecked, isIndeterminate: someChecked && !allChecked };
  };

  const handlePageClick = (section: Section, page: Page) => {
    setCurrentSection(section);
    setCurrentPage(page);
  };

  const handleNextClick = () => {
    const currentSectionIndex = workflow.sections.findIndex((section) => section.name === currentSection.name);
    const currentPageIndex = currentSection.pages.findIndex((page) => page.name === currentPage.name);

    if (currentPageIndex < currentSection.pages.length - 1) {
      // Move to the next page in the current section
      setCurrentPage(currentSection.pages[currentPageIndex + 1]);
    } else if (currentSectionIndex < workflow.sections.length - 1) {
      // Move to the first page of the next section
      const nextSection = workflow.sections[currentSectionIndex + 1];
      setCurrentSection(nextSection);
      setCurrentPage(nextSection.pages[0]);
    }
  };

  return (
    <VStack w="full" h="100%" alignItems="stretch" overflow={"hidden"}>
      <HStack w="full" h="full" alignItems="flex-start" gap={10} mt={10}>
        <SectionNavigation
          sections={workflow.sections}
          getSectionState={getSectionState}
          checkedItems={checkedItems}
          handlePageClick={handlePageClick}
        />

        <VStack alignItems="flex-start" justifyContent="flex-start" w="full" h="full" position="relative">
          <Breadcrumb mb={4}>
            <BreadcrumbItem>
              <BreadcrumbLink
              // onClick={handleNextClick}
              >
                {currentSection.name}
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{currentPage.name}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <Container maxWidth="container.lg" height="100%">
            <VStack alignItems="flex-start" justifyContent="flex-start" w="full" h="full" gap={10}>
              {currentPage.questions.map((question) => (
                <DynamicQuestion
                  question={question}
                  handleNextClick={handleNextClick}
                  session={session}
                  setSession={(s) => setSession({ ...(session ?? {}), ...s })}
                />
              ))}
            </VStack>

            <Button
              position="absolute"
              bottom={4}
              right={4}
              colorScheme="brand"
              variant="outline"
              onClick={handleNextClick}
            >
              Continue
            </Button>
          </Container>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default SopDemoPage;
